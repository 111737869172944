import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildLoadColoursUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { MXColour, CustomColour, ColoursResponse } from '../types';

export const loadColours = (
    actionName: string,
    processResultFunction: Function,
): Observable<{ result: ColoursResponse; actionName: string; processResultFunction: Function }> => {
    const url = buildLoadColoursUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<{ custom_colours: any; mxcolours: any }>) => {
            const { custom_colours, mxcolours } = response;
            const mxColours = convertToCamel<MXColour[]>(mxcolours).sort(
                (a, b) => a.index - b.index,
            );
            const customColours = convertToCamel<CustomColour[]>(custom_colours);
            return observableOf({
                result: { customColours, mxColours },
                actionName,
                processResultFunction,
            });
        }),
    );
};
